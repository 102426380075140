import React from 'react';
import './styles.scss';
import strings from 'utils/strings';
import moment from 'moment';
import NotificatioDetails from './notificationDetails';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setCurrentUser} from '../../actions/index';
import updateUserTime from 'api/updateUserTime';
import getUserInfo from 'api/getUserInfo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenToSquare, faTrash} from '@fortawesome/free-solid-svg-icons';
import InformationPane from 'components/InformationPane';
import deleteNotification from 'api/deleteNotification';
import AlertBox from 'components/CreateNotification/alert';
import EditNotificationModal from './editNotificationModel';

class ViewNotifications extends React.Component {
	constructor() {
		super();
		this.state = {
			isModelOpen: false,
			selectedNotification: [],
			notificationList: [],
			unReadNotificationsIds: [],
			unReadNotifications: [],
			renderPaneAlert: false,
			paneMessage: 'Er du sikker på at du vil slette dette varslinget?',
			showAlert: false,
			error: null,
			success: null,
			message: null,
			isEditModelOpen: false,
		};
		this.onConfirm = this.onConfirm.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onListItemClick = this.onListItemClick.bind(this);
		this.onListItemDelete = this.onListItemDelete.bind(this);
		this.fetchUserInfo = this.fetchUserInfo.bind(this);
		this.setUnreadNotifications = this.setUnreadNotifications.bind(this);
		this.updateTime = this.updateTime.bind(this);
		this.onEditItemClick = this.onEditItemClick.bind(this);
	}

	componentDidMount() {
		this.fetchUserInfo().then(() => {
			this.updateTime();
		});
	}

	onConfirm = () => {
		this.setState({
			isModelOpen: false,
		});
	};

	onCancel = (a) => {
		this.setState({
			isModelOpen: false,
		});
	};

	onEditModelConfirm = () => {
		this.setState({
			isEditModelOpen: false,
		});
	};

	onEditModelCancel = (a) => {
		this.setState({
			isEditModelOpen: false,
		});
	};

	onListItemClick = (id, notifications, unReadNotifications) => {
		const notification = notifications.filter((item) => item.id === id);

		const updatedUnReadNotifications = unReadNotifications.filter(
			(item) => item.id !== notification[0].id
		);
		this.setState({
			isModelOpen: true,
			selectedNotification: notification[0],
			unReadNotifications: updatedUnReadNotifications,
		});
		this.setUnreadNotifications(updatedUnReadNotifications);
	};

	onEditItemClick = (id, notifications) => {
		const notification = notifications.filter((item) => item.id === id);
		this.setState({
			isEditModelOpen: true,
			selectedNotification: notification[0],
		});
	};

	onListItemDelete = (id) => {
		deleteNotification(id).then((res) => {
			if (!res) {
				this.setState({
					showAlert: true,
					error: true,
					success: false,
					message: 'Ops! something went wrong.',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			} else {
				this.setState({
					showAlert: true,
					error: false,
					success: true,
					message: 'Notification deleted successfully!',
				});
				this.fetchUserInfo().then(() => {
					this.updateTime();
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			}
		});
	};

	fetchUserInfo() {
		const {currentUser} = this.props;

		if (currentUser) {
			return getUserInfo().then((user) => {
				const {notifications, unReadNotifications = []} = user;

				this.setState({
					notificationList: notifications,
					unReadNotifications: unReadNotifications,
				});
				this.setUnreadNotifications(unReadNotifications);
			});
		}
	}
	setUnreadNotifications(list) {
		const tempArray = [];
		list.map((item) => {
			tempArray.push(item.id);
		});
		this.setState({
			unReadNotificationsIds: tempArray,
		});
	}
	updateTime() {
		updateUserTime()
			.then((user) => {})
			.catch(() => {});
	}

	notificationTime = (creationDate) => {
		const now = moment.utc().format('YYYY-MM-DD HH:mm:ss');
		const notificationTime = moment(creationDate);

		const isFuture = notificationTime.isAfter(now);

		const displayTime = isFuture
			? `Notification Sscheduled at: ${notificationTime.format('LLL')}`
			: notificationTime.format('LLL');

		return <>{displayTime}</>;
	};
	render() {
		const {
			isModelOpen,
			selectedNotification,
			notificationList,
			unReadNotificationsIds,
			unReadNotifications,
			renderPaneAlert,
			notificationToDelete,
			showAlert,
			success,
			message,
			isEditModelOpen,
		} = this.state;
		const getChars = (htmlString) => {
			const text = htmlString.replace(/(<([^>]+)>)/gi, '');
			return text.substring(0, 200);
		};
		const {
			currentUser: {isSuperAdmin},
		} = this.props;

		return (
			<div>
				<h2 className="notification-list-heading">
					{strings.allnotifications}
				</h2>
				<ul className="notification-list-main">
					{Boolean(notificationList.length < 1) && (
						<p>{strings.nonotificationsfound}</p>
					)}
					{notificationList.map((notification) => (
						<li key={notification.id}>
							<div
								className={
									unReadNotificationsIds.includes(
										notification.id
									)
										? 'notification-main-div-unread'
										: 'notification-main-div'
								}
							>
								<div
									className={
										unReadNotificationsIds.includes(
											notification.id
										)
											? 'notification-list-notification-unread'
											: 'notification-list-notification'
									}
									onClick={() => {
										this.onListItemClick(
											notification.id,
											notificationList,
											unReadNotifications
										);
									}}
								>
									<span className="notification-list-time">
										{this.notificationTime(
											notification.createdAt
										)}
									</span>
									<span className="notification-list-subject">
										{notification.subject}
									</span>
									<span className="notification-list-summary">
										{notification.summary}
									</span>
									<span className="notification-list-notificationdetails">
										{getChars(notification.details)}....
									</span>
								</div>
								{isSuperAdmin && (
									<div className="notification-list-buttons">
										<button
											className="lit-mytests-header-mytest-icon-btn-primary"
											title="Edit Notification"
											onClick={() => {
												this.onEditItemClick(
													notification.id,
													notificationList
												);
											}}
										>
											<FontAwesomeIcon
												size="lg"
												className="lit-mytests-header-mytest-icon-btn-icon"
												icon={faPenToSquare}
											/>
										</button>

										<button
											className="lit-mytests-header-mytest-icon-btn-ternary"
											title="Delete Notification"
											onClick={() => {
												this.setState({
													renderPaneAlert: true,
													notificationToDelete:
														notification.id,
												});
											}}
										>
											<FontAwesomeIcon
												size="lg"
												className="lit-mytests-header-mytest-icon-btn-icon"
												icon={faTrash}
											/>
										</button>
									</div>
								)}
							</div>
						</li>
					))}
				</ul>
				{isModelOpen && (
					<NotificatioDetails
						onConfirm={this.onConfirm}
						onCancel={this.onCancel}
						selectedNotification={selectedNotification}
					/>
				)}
				{renderPaneAlert && (
					<InformationPane
						paneMessage={this.state.paneMessage}
						onApproval={() => {
							this.setState({
								renderPaneAlert: false,
							});
							this.onListItemDelete(notificationToDelete);
						}}
						onCloseWindow={() => {
							this.setState({
								renderPaneAlert: false,
							});
						}}
						onDisproval={() => {
							this.setState({
								renderPaneAlert: false,
							});
						}}
						category={'confirmation'}
					/>
				)}
				{showAlert && (
					<AlertBox
						type={success ? 'success' : 'error'}
						message={message}
					/>
				)}
				{isEditModelOpen && (
					<EditNotificationModal
						onConfirm={this.onEditModelConfirm}
						onCancel={this.onEditModelCancel}
						selectedNotification={selectedNotification}
						fetchNotifications={() =>
							this.fetchUserInfo().then(() => {
								this.updateTime();
							})
						}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {
	handleSetCurrentUser: setCurrentUser,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ViewNotifications)
);
