import React from 'react';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import './styles.scss';
import {formats, modules} from './config';
import strings from 'utils/strings';
import ConfirmationModal from './confirmationModel';
import createNotification from 'api/createNotification';
import AlertBox from './alert';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {setCurrentUser} from 'actions/index';

import ToggleSwitch from 'components/Switch';
import DateTimeInput from 'components/Data&TimeComponent';

class TextEditor extends React.Component {
	constructor() {
		super();

		this.state = {
			isModelOpen: false,
			notificationContent: null,
			subject: '',
			showAlert: false,
			error: null,
			success: null,
			message: '',
			summary: '',
			isSwitchOn: false,
			dateTime: '',
		};
		this.handleProcedureContentChange = this.handleProcedureContentChange.bind(
			this
		);
		this.onConfirm = this.onConfirm.bind(this);
		this.toggleSwitch = this.toggleSwitch.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
	}

	toggleSwitch = () => {
		this.setState((prevState) => ({
			isSwitchOn: !prevState.isSwitchOn,
		}));
	};

	handleDateChange = (event) => {
		this.setState({dateTime: event.target.value});
	};

	handleProcedureContentChange = (content) => {
		if (content === '<p><br></p>') {
			this.setState({
				notificationContent: null,
			});
		} else {
			this.setState({
				notificationContent: content,
			});
		}
	};

	onConfirm = () => {
		const {notificationContent} = this.state;
		let cleanNotificationContent = notificationContent.replaceAll(
			'&gt;',
			'>'
		);
		cleanNotificationContent = notificationContent.replaceAll('&lt;', '<');
		createNotification(
			cleanNotificationContent,
			this.state.subject,
			this.state.summary
		).then((res) => {
			if (!res) {
				this.setState({
					showAlert: true,
					isModelOpen: false,
					error: true,
					success: false,
					message: 'Ops! something went wrong.',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			} else {
				this.setState({
					showAlert: true,
					isModelOpen: false,
					notificationContent: null,
					error: false,
					success: true,
					message: strings.notificationcreated,
					subject: '',
					summary: '',
				});
				setTimeout(() => {
					this.setState({
						showAlert: false,
					});
				}, 3000);
			}
		});
	};

	onCancel = (a) => {
		this.setState({
			isModelOpen: false,
			error: null,
			success: null,
			message: '',
		});
	};

	render() {
		const {
			isModelOpen,
			notificationContent,
			showAlert,
			message,
			success,
			subject,
			summary,
			isSwitchOn,
			dateTime,
		} = this.state;
		return (
			<div>
				<div>
					<h3 className="lit-text-editor-heading">
						Create a new notification
					</h3>
					<div className="lit-text-editor-input">
						<label>Subject:</label>
						<div>
							<input
								className="lit-input__field"
								value={subject}
								onChange={(e) => {
									this.setState({
										subject: e.target.value,
									});
								}}
								required
								type="text"
							/>
						</div>
					</div>
					<div className="lit-text-editor-input">
						<label>Summary:</label>
						<div>
							<textarea
								className="lit-input__field"
								value={summary}
								onChange={(e) => {
									this.setState({
										summary: e.target.value,
									});
								}}
								required
								type="text"
							/>
						</div>
					</div>
					<div className="lit-text-editor-input-switch">
						Schedule a notification: &nbsp;{' '}
						<ToggleSwitch
							isSwitchOn={isSwitchOn}
							toggleSwitch={this.toggleSwitch}
						/>
					</div>
					{isSwitchOn && (
						<div className="lit-text-editor-input">
							<DateTimeInput
								dateTime={dateTime}
								handleDateChange={this.handleDateChange}
							/>
						</div>
					)}
					<div className="lit-text-editor-content">
						<ReactQuill
							value={notificationContent}
							theme="snow"
							modules={modules}
							formats={formats}
							placeholder="write your content ...."
							onChange={this.handleProcedureContentChange}
						></ReactQuill>
					</div>
				</div>
				<div className="lit-post-notification-btn-div">
					<button
						className="lit-btn lit-btn--small bg-primary"
						onClick={() => {
							this.setState({
								isModelOpen: true,
							});
						}}
						disabled={!subject || !notificationContent || !summary}
					>
						{strings.postNotification}
					</button>
				</div>
				{showAlert && (
					<AlertBox
						type={success ? 'success' : 'error'}
						message={message}
					/>
				)}

				{isModelOpen && (
					<ConfirmationModal
						onConfirm={this.onConfirm}
						onCancel={this.onCancel}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {currentUser} = state;

	return {
		currentUser,
	};
};

const mapDispatchToProps = {
	handleSetCurrentUser: setCurrentUser,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TextEditor)
);
